import React, { Component } from 'react'
import { graphql, navigate } from 'gatsby'
import Helmet from 'react-helmet'
import _ from 'lodash'
import fetch from 'isomorphic-unfetch';
import Cookies from 'js-cookie';

import Layout from '../../components/layout'
import PageHeader from '../../components/page-header'
import SectionHeader from '../../components/section-header'
import LinkCard from '../../components/link-card'

class ResourceGuidePage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      success: false,
      error: false,
      firstname: "",
      lastname: "",
      email: "",
      company: "",
      country_dropdown: "",
      industry_dropdown: "",
      showConsent: true,
      consent: "",
      errors: {
        firstname: '',
        lastname: '',
        email: '',
        company: '',
        country: '',
        industry: ''
      }
    }
  }

  render() {
    const { uid, data: resourceGuide } = _.get(this.props.data, [
      'allPrismicResourceDocument',
      'edges',
      '0',
      'node',
    ])

    const heroColour = resourceGuide.hero_bg_colour !== null || undefined ? `${resourceGuide.hero_background_colour}` : `#121212`

    const heroStyle = {
      backgroundColor: heroColour
    }

    const secondaryColour = resourceGuide.secondary_bg_colour !== null || undefined ? `${resourceGuide.secondary_bg_colour}` : `white`

    const sectionStyle = {
      backgroundColor: secondaryColour,
      color: `#121212`
    }

    // Versett hubspot for production
    const portalId = "5164796";
    const formId = resourceGuide.hubspot_form_id;

    // Rodrigo Peters personal hubspot for tesing only
    // const portalId = "22152867";
    // const formId = "fbc82a9d-49b0-41fe-9cc4-ecc19402e642";

    //form validation
    const validateForm = (errors) => {
      let valid = true;
      Object.values(errors).forEach(
        (val) => val.length === 0 && (valid = false)
      );
      return valid;
    }

    const handleChange = (event) => {
      event.preventDefault();
      this.setState({showConsent: true})
      const { name, value } = event.target;
      let errors = this.state.errors;

      switch (name) {
        case 'firstname':
          errors.firstname =
            value.length === 0
              ? 'Please complete this required field.'
              : '';
          break;
        case 'lastname':
          errors.lastname =
            value.length === 0
              ? 'Please complete this required field.'
              : '';
          break;
        case 'email':
          errors.email =
            value.length === 0
              ? 'Please complete this required field.'
              : '';
          break;
        case 'company':
          errors.company =
            value.length === 0
              ? 'Please complete this required field.'
              : '';
          break;
        case 'country_dropdown':
          errors.country =
            value.length === 0
              ? 'Please complete this required field.'
              : '';
          break;
        case 'industry_dropdown':
          errors.industry =
            value.length === 0
              ? 'Please complete this required field.'
              : '';
          break;
        default:
          break;
      }
      this.setState({errors, [name]: value})
      this.setState({[name]: event.target.value})
    }

    const {errors} = this.state;

    const submitForm = e => {
      if (e) e.preventDefault();
      const firstName = this.state.firstname;
      const lastName = this.state.lastname;
      const emailAddress = this.state.email;
      const company = this.state.company;
      const country = this.state.country_dropdown;
      const industry = this.state.industry_dropdown;
      const requiredCompleted = firstName !== ""  && lastName !== "" && emailAddress !== "" && company !== "" && country !== "" && industry !== "";

      if (firstName === "") {
        this.setState({errors: { ...this.state.errors, firstname: 'Please complete this required field.' }})
      } else if (lastName === "") {
        this.setState({errors: { ...this.state.errors, lastname: 'Please complete this required field.' }})
      } else if (emailAddress === "") {
        this.setState({errors: { ...this.state.errors, email: 'Please complete this required field.' }})
      } else if (company === "") {
        this.setState({errors: { ...this.state.errors, company: 'Please complete this required field.' }})
      } else if (country === "") {
        this.setState({errors: { ...this.state.errors, country: 'Please complete this required field.' }})
      } else if (industry === "") {
        this.setState({errors: { ...this.state.errors, industry: 'Please complete this required field.' }})
      } else {
        this.setState({errors: { ...this.state.errors, email: '', firstname: '', lastname: '', company: '', country: '', industry: '' }})
      }

      if(!validateForm(this.state.errors) && requiredCompleted) {
        const isBrowser = typeof window !== 'undefined';
        const hutk = isBrowser ? Cookies.get('hubspotutk') : null;
        const pageUri = isBrowser ? window.location.href : null;
        const pageName = isBrowser ? document.title : null;
        const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

        this.setState({
          loading: true,
        })

        const body = {
          submittedAt: Date.now(),
            fields:[
              {
                name: 'firstname',
                value: this.state.firstname,
              },
              {
                name: 'lastname',
                value: this.state.lastname,
              },
              {
                name: 'email',
                value: this.state.email,
              },
              {
                name: 'company',
                value: this.state.company
              },
              {
                name: 'country_dropdown',
                value: this.state.country_dropdown
              },
              {
                name: 'industry_dropdown',
                value: this.state.industry_dropdown
              },
              {
                name: 'LEGAL_CONSENT.subscription_type_5775304',
                value: this.state.consent
              },
            ],
              context: {
              hutk,
              pageUri,
              pageName,
          }
        }

        fetch(postUrl, {
          method: 'post',
          body: JSON.stringify(body),
          headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json, application/xml, text/plain, text/html, *.*',
          }),
        })
          .then(res => res.json())
          .then(() => {
            this.setState({
              success: true,
              error: false,
              loading: false,
              firstname: "",
              lastname: "",
              email: "",
              company: "",
              country: "",
              industry: "",
              consent: "",
              showConsent: false,
            })
            navigate(`/download-thanks/${uid}`, { replace: true })
          })
          .catch(err => {
            this.setState({
              success: false,
              error: err,
              loading: false,
            })
          });
      }
    };

    const links = this.props.data?.allPrismicRecentLinks.edges[0].node.data
    const clients = this.props.data?.allPrismicClients.edges

    return (
      <React.Fragment>
        <Helmet
          title="Versett – Resources"
          meta={[
            {
              name: 'description',
              content:
                'Versett is designed to be a place for doing meaningful work. Our team is tight-knit and dedicated to building great products.',
            },
            { name: 'keywords', content: 'versett, career, job, work' },
            { property: 'og:title', content: 'Versett – Resources' },
            {
              property: 'og:description',
              content:
                'Versett is designed to be a place for doing meaningful work. Our team is tight-knit and dedicated to building great products.',
            },
            {
              property: 'og:image',
              content: 'https://versett.com/images/social-careers.jpg',
            },
            { property: 'og:url', content: 'https://versett.com/resources/' },
            { name: 'twitter:card', content: 'summary_large_image' },
            { property: 'og:site_name', content: 'Versett' },
            { name: 'twitter:image:alt', content: 'Resources' },
          ]}
        >
          <html lang="en" className="s-resources" />
        </Helmet>
        <Layout>
          {/* Hero Section */}
          <section style={heroStyle} className="s-container s-page__hero resources grid grid--2col-2-1">
            <div className='resource-hero-container'>
              <PageHeader
                heading="Resources"
                lede={resourceGuide.hero_lede.text}
                subheading={resourceGuide.hero_subheading.text}
              />
              <div className="resource-hero-image--mobile">
                <img
                  className="hero-image"
                  src={resourceGuide.resource_image.url}
                  alt={resourceGuide.resource_image.alt}
                />
              </div>
              <form
                data-form-id={formId}
                data-portal-id={portalId}
                disabled={this.state.loading}
                onSubmit={submitForm}
                className="hubspot-form"
              >
                <fieldset className="form-fieldset">
                <div className="grid grid--2col-1-1">
                  <div className="input-container">
                    <label className="form-label" htmlFor="firstname">
                      First Name*
                    </label>
                    <input
                      name="firstname"
                      className="form-input"
                      type="text"
                      value={this.state.firstname}
                      onChange={(e) => {handleChange(e)}}
                      onBlur={(e) => {handleChange(e)}}
                      required
                    />
                    {errors.firstname.length > 0 && <div className='error'>{this.state.errors.firstname}</div>}
                  </div>
                  <div className="input-container">
                    <label className="form-label" htmlFor="lastname">
                      Last Name*
                    </label>
                    <input
                      name="lastname"
                      className="form-input"
                      type="text"
                      value={this.state.lastname}
                      onChange={(e) => {handleChange(e)}}
                      onBlur={(e) => {handleChange(e)}}
                      required
                    />
                    {errors.lastname.length > 0 && <div className='error'>{errors.lastname}</div>}
                  </div>
                </div>
                <div className="input-container">
                  <label className="form-label" htmlFor="email">
                      Email*
                  </label>
                  <input
                    name="email"
                    className="form-input"
                    type="text"
                    value={this.state.email}
                    onChange={(e) => {handleChange(e)}}
                    onBlur={(e) => {handleChange(e)}}
                    required
                  />
                  {errors.email.length > 0 && <div className='error'>{errors.email}</div>}
                </div>
                </fieldset>
                <fieldset className="form-fieldset">
                  <div className="input-container">
                    <label className="form-label" htmlFor="company">
                    Company Name*
                    </label>
                    <input
                      name="company"
                      className="form-input"
                      type="text"
                      value={this.state.company}
                      onChange={(e) => {handleChange(e)}}
                      onBlur={(e) => {handleChange(e)}}
                      required
                    />
                    {errors.company.length > 0 && <div className='error'>{errors.company}</div>}
                  </div>
                  <div className="grid grid--2col-1-1">
                    <div className="input-container">
                      <div className="label--icon">
                        <label className="form-label" htmlFor="country_dropdown">
                          Region/Country*
                        </label>
                      </div>
                      <select
                        className="form-input"
                        name="country_dropdown"
                        label="country"
                        value={this.state.country_dropdown}
                        onChange={(e) => {handleChange(e)}}
                        onBlur={(e) => {handleChange(e)}}
                        required
                      >
                        <option value=""></option>
                        <option value="Afghanistan">Afghanistan</option>
                        <option value="Aland Islands">Aland Islands</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antarctica">Antarctica</option>
                        <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bonaire, Sint Eustatius and Saba">Bonaire, Sint Eustatius and Saba</option>
                        <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                        <option value="Botswana">Botswana</option>
                        <option value="Bouvet Island">Bouvet Island</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                        <option value="Brunei Darussalam">Brunei Darussalam</option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">Central African Republic</option>
                        <option value="Chad">Chad</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">Christmas Island</option>
                        <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Congo, Democratic Republic of the Congo">Congo, Democratic Republic of the Congo</option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cote D'Ivoire">Cote D'Ivoire</option>
                        <option value="Croatia">Croatia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Curacao">Curacao</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">Dominican Republic</option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">French Polynesia</option>
                        <option value="French Southern Territories">French Southern Territories</option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guernsey">Guernsey</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                        <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="India">India</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Isle of Man">Isle of Man</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jersey">Jersey</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                        <option value="Korea, Republic of">Korea, Republic of</option>
                        <option value="Kosovo">Kosovo</option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macao">Macao</option>
                        <option value="Macedonia, the Former Yugoslav Republic of">Macedonia, the Former Yugoslav Republic of</option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">Marshall Islands</option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                        <option value="Moldova, Republic of">Moldova, Republic of</option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montenegro">Montenegro</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Namibia">Namibia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherlands">Netherlands</option>
                        <option value="Netherlands Antilles">Netherlands Antilles</option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau">Palau</option>
                        <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">Papua New Guinea</option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Philippines">Philippines</option>
                        <option value="Pitcairn">Pitcairn</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Reunion">Reunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russian Federation">Russian Federation</option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="Saint Barthelemy">Saint Barthelemy</option>
                        <option value="Saint Helena">Saint Helena</option>
                        <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                        <option value="Saint Lucia">Saint Lucia</option>
                        <option value="Saint Martin">Saint Martin</option>
                        <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                        <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                        <option value="Samoa">Samoa</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Serbia">Serbia</option>
                        <option value="Serbia and Montenegro">Serbia and Montenegro</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leone">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Sint Maarten">Sint Maarten</option>
                        <option value="Slovakia">Slovakia</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="South Georgia and the South Sandwich Islands">South Georgia and the South Sandwich Islands</option>
                        <option value="South Sudan">South Sudan</option>
                        <option value="Spain">Spain</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                        <option value="Swaziland">Swaziland</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                        <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                        <option value="Thailand">Thailand</option>
                        <option value="Timor-Leste">Timor-Leste</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Emirates">United Arab Emirates</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="United States">United States</option>
                        <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Viet Nam">Viet Nam</option>
                        <option value="Virgin Islands, British">Virgin Islands, British</option>
                        <option value="Virgin Islands, U.s.">Virgin Islands, U.s.</option>
                        <option value="Wallis and Futuna">Wallis and Futuna</option>
                        <option value="Western Sahara">Western Sahara</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                      </select>
                      {errors.country.length > 0 && <div className='error'>{errors.country}</div>}
                    </div>
                    <div className="input-container">
                      <div className="label--icon">
                        <label className="form-label" htmlFor="industry">
                          Company Industry*
                        </label>
                      </div>
                    <select
                        className="form-input"
                        name="industry_dropdown"
                        label="industry"
                        value={this.state.industry_dropdown}
                        onChange={(e) => {handleChange(e)}}
                        onBlur={(e) => {handleChange(e)}}
                        required
                      >
                        <option value=""></option>
                        <option value="Finance">Finance</option>
                        <option value="Retail">Retail</option>
                        <option value="Travel">Travel</option>
                        <option value="Higher Education">Higher Education</option>
                        <option value="Government">Government</option>
                        <option value="Non-Profit">Higher Education</option>
                        <option value="Other">Other</option>
                      </select>
                      {errors.industry.length > 0 && <div className='error'>{errors.industry}</div>}
                    </div>
                  </div>
                </fieldset>
                {
                  this.state.showConsent && (
                    <fieldset className="form-fieldset form-consent">
                      <p>Versett is committed to protecting and respecting your privacy, and we’ll only use your personal information to administer your account and to provide the products and services you requested from us. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please tick below to say how you would like us to contact you:</p>
                      <div className="consent-container">
                        <input type="checkbox" name="LEGAL_CONSENT.subscription_type_5775304" className="form-input form-input--checkbox" onChange={(e) => {handleChange(e)}}/>
                        <label className="form-label" htmlFor="LEGAL_CONSENT.subscription_type_5775304">I agree to receive other communications from Versett.</label>
                      </div>
                      <p>You can unsubscribe from these communications at any time. For more information on how to unsubscribe, our privacy practices, and how we are committed to protecting and respecting your privacy, please review our Privacy Policy.</p>
                      <p>By clicking submit below, you consent to allow Versett to store and process the personal information submitted above to provide you the content requested.</p>
                    </fieldset>
                  )
                }
                <div>
                  <button
                    className="button button--outline"
                    type="submit"
                    onClick={submitForm}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
            <div className="resource-hero-image--desktop">
              <img
                className="hero-image"
                src={resourceGuide.resource_image.url}
                alt={resourceGuide.resource_image.alt}
              />
            </div>
          </section>
          <div style={sectionStyle}>
            {/* Sub Section 1 - two column */}
            {
              resourceGuide.sec1_heading.text &&
              <section className="s-section s-container grid grid--2col-1-2 s-sec1">
                <h2>{resourceGuide.sec1_heading.text}</h2>
                <div>
                  <h4>{resourceGuide.sec1_subheading.text}</h4>
                  <p>{resourceGuide.sec1_description.text}</p>
                </div>
              </section>
            }
            {/* Sub Section 2 - two column */}
            {
              resourceGuide.sec2_heading.text &&
              <section className="s-section s-container grid grid--2col-1-2 s-sec2">
                <h2>{resourceGuide.sec2_heading.text}</h2>
                <div>
                  <p>{resourceGuide.sec2_description.text}</p>
                </div>
              </section>
            }
            {/* Sub Section 3- one column */}
            {
              resourceGuide.sec3_lede.text &&
              <section className="s-section s-container s-sec3">
                <SectionHeader
                  heading={resourceGuide.sec3_lede.text}
                  lede={resourceGuide.sec3_heading.text}
                  subheading={resourceGuide.sec3_description.text}
                  classes="t-section-heading--full-page"
                />
              </section>
            }
            {/* Sub Section 4 - one column with images*/}
            <section className="s-container s-sec4">
              <img src={resourceGuide.image_one.url} alt={resourceGuide.image_one.alt} />
              <p className="sec4-description">{resourceGuide.sec4_description.text}</p>
              <img src={resourceGuide.image_two.url} alt={resourceGuide.image_two.alt} />
            </section>
            {/* Partner Section */}
            <section className="s-container s-partners">
              <div className="grid grid--4col">
                {clients.map((client, key) => {
                    return (
                      <img
                        key={key}
                        className="client-logo"
                        src={client.node.data.logo.url}
                        alt={client.node.data.name}
                      />
                    )
                  })
                }
                {
                  (clients.length % 2 === 1) &&
                    <img
                      className="client-logo"
                      src=""
                      alt=""
                    />
                }
              </div>
            </section>
            {/* Call to Action Section - two columns */}
            <section className="s-container s-contact grid grid--2col-1-1">
              <div>
                <img className="cta-image" src={resourceGuide.image_three.url} alt={resourceGuide.image_three.alt} />
              </div>
              <div className="cta-content">
                <h4>{resourceGuide.cta_heading.text}</h4>
                <p className='cta-description'>{resourceGuide.cta_description.text}</p>
                <a className="link--outline" href="#" onClick={(event) => {event.preventDefault(); window.scrollTo(0, 0)}} rel="noopener noreferrer">Get in touch with us today!</a>
              </div>
            </section>
            {/* Articles Section - Three column */}
            <section className="s-container s-trendingArticles resources">
              <p className="articles-lede">Trending Articles</p>
              <div className="cards grid grid--3col">
                {links.links.map((link, index) => (
                  <LinkCard key={index} card={link} />
                ))}
              </div>
            </section>
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}

export default ResourceGuidePage

export const query = graphql`
  query($uid: String, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allPrismicResourceDocument(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          uid
          data {
            hubspot_form_id
            hero_bg_colour
            secondary_bg_colour
            hero_lede {
              text
            }
            hero_subheading {
              text
            }
            sec1_heading {
              text
            }
            sec1_subheading {
              text
            }
            sec1_description {
              text
            }
            sec2_heading {
              text
            }
            sec2_description {
              text
            }
            sec3_lede {
              text
            }
            sec3_heading {
              text
            }
            sec3_description {
              text
            }
            sec4_description {
              text
            }
            cta_heading {
              text
            }
            cta_description {
              text
            }
            resource_image {
              alt
              url
            }
            image_one {
              url
              alt
            }
            image_two {
              url
              alt
            }
            image_three {
              url
              alt
            }
          }
        }
      }
    }
    allPrismicClients(
      sort: { fields: [data___weight], order: ASC }
      filter: {
        data: { display_on_front_page: { eq: "Yes" } }
        lang: { eq: $language }
        }
    ) {
      edges {
        node {
          id
          data {
            name
            lede
            description
            tags
            urls {
              url
              url_text
            }
            company_type
            logo {
              url
            }
          }
        }
      }
    }
    allPrismicRecentLinks(filter: { lang: { eq: $language } }) {
      edges {
        node {
          data {
            links {
              description
              source
              url
              photo {
                url
                thumbnails {
                  link_300_1x {
                    url
                  }
                  link_300_2x {
                    url
                  }
                  link_500_1x {
                    url
                  }
                  link_500_2x {
                    url
                  }
                  link_700_1x {
                    url
                  }
                  link_700_2x {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
